
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import Utils from "@/utility/utils";
import Card from "primevue/card";
import Dialog from "primevue/dialog"
import DataGrid from "@/components/DataGrid.vue";
import Button from 'primevue/button';
import Footer from "@/components/Footer.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SendOrderDialog from "@/components/UI/SendOrderDialog.vue";
import SOQuotesService from '@/services/SOQuotesService';
import store from '@/store';
import NotificationCard from "@/components/Notification-Card.vue";
import Checkbox from 'primevue/checkbox';
import SOQuote from '@/types/soquotes';

const soQuotesService = new SOQuotesService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  components: {
    Button,
    Card,
    DataGrid,
    Dialog,
    Footer,
    LoadingSpinner,
    SendOrderDialog,
    NotificationCard,
    Checkbox
  },
  data() {
    return {
      isLoadingSoQuotes: false,
      orderRecordsBy: "",
      soQuotes: [] as Array<SOQuote>,
      first: 0,
      page: 1,
      rows: 10,
      sort: {
        sortBy: "",
        sortOrder: 0,
      },
      order: false,
      oldSoQuote: null as unknown,
      isEditing: false,
      newQuote: false,
      id: "",
      emailLoading: false,
      quoterIdItems: [],
      showEmailPDFDialog: false,
      quoteIdToSend: '',
      showReorderConfirmation: false,
      showPricesCheckboxOnDialog: false,
      showModelsCheckboxOnDialog: false,
      showModelsSelection: false,
      showPricesSelection: false,
      showPDFOptionsDialog: false,
      forcePDFFetch: false,
      PDFOptionDialogClosedResolve: null as any,
      addingReorderItems: [] as Array<any>,
      columns: [
        { field: 'id',header: 'ID',sort: true ,filter: 'id', filterType: 'text'},
        { field: 'date',header: 'Date',sort: true, filter: 'date', filterType: 'calendarRange' },
        { field: 'quoted_by',header: 'Quoted By',sort: false  },
        { field: 'quote_amount',header: 'Quote Total',sort: true },
      ],
      tableActions: [
        {
          name: "downloadPDF",
          tooltipMsg: "Download PDF",
          getIconClass: (data: any) => this.downloadIcon(data.id),
          action: (data: any): void => this.download(data.id)
        },
        {
          name: "email",
          icon: "pi pi-envelope",
          tooltipMsg: "Email Quote",
          action: (data: any) => this.handleEmailOrderPDF(data.id)
        },
        {
          name: "addToCart",
          icon: "pi pi-shopping-cart",
          tooltipMsg: "Add To Cart",
          action: (data: any) => this.handleShowReorderConfirmation(data.li_items),
        },
      ],
      filters: {
        id: {
          value: undefined as any,
          matchMode: FilterMatchMode.EQUALS,
          newVal: undefined as any,
        },
        status: {
          value: undefined as any,
          matchMode: FilterMatchMode.IN,
          newVal: undefined as any,
        },
        date: {
          value:  undefined as any,
          matchMode: FilterMatchMode.BETWEEN,
          newVal: undefined as any,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getRangeEnd: "sales/getRangeEnd",
      getQuotes: "sales/getQuotePDF",
      getLoadingQuotes: "sales/getLoadingQuotes",
      getOrder: "pos/getCurrentOrder",
      getCustomer: "customer/getCust",
      getUser: "session/getUser",
      getControlFlags: "control/getControlFlags",
      getCartQuotePriceModel: "control/getCartQuotePriceModel",
    }),
  },
  created() {
    this.fetchQuotesData(false);
    this.setControlFlags();
    this.fetchControl({
      id: "QUOTE",
      procedure: "QUOTE.CONTROL",
      filename: "CONTROL",
    });
  },
  methods: {
    ...mapActions({
      setFirstRow: "sales/setFirstRow",
      fetchQuotePDF: "sales/fetchQuotePDF",
      fetchControl: "control/fetchControls",
      addNotification: "notification/add",
      clearOrder: "pos/clearOrder",
      updatePartQty: "pos/updatePartQty",
      addPartToOrder: "pos/addPartToOrder",
      setQuoteToEdit: "sales/setQuoteToEdit",
    }),
    setControlFlags(){
      this.showModelsCheckboxOnDialog = this.getControlFlags ? this.getControlFlags.some((controlFlag: { quoteEmailModelPrompt: string; }) => controlFlag.quoteEmailModelPrompt === "Y") : false;
      this.showPricesCheckboxOnDialog = this.getControlFlags ? this.getControlFlags.some((controlFlag: { quoteEmailPricesPrompt: string; }) => controlFlag.quoteEmailPricesPrompt === "Y") : false;

    },
    fetchQuotesData(addSoQuote: boolean) {
      this.isLoadingSoQuotes = true;
      let sortBy
      switch(this.sort.sortBy) {
        case "quote_amount":
          sortBy= "quote.amount";
          break;
        default:
          sortBy = this.sort.sortBy
          break;
      }
      let quoteIds = "";
      let dateFilter = {} as any;
      if (this.filters.date.value) {
        if (this.filters.date.value[0] != null) dateFilter.selectedDateStart = this.filters.date.value[0];
        if (this.filters.date.value[1] != null) dateFilter.selectedDateEnd = this.filters.date.value[1];

        dateFilter.selectedDateStart = this.formatStringDate(dateFilter.selectedDateStart);
        dateFilter.selectedDateEnd = this.formatStringDate(dateFilter.selectedDateEnd);
      }
      
      soQuotesService.getSOQuotes({
        addSoQuote: addSoQuote,
        custs: this.getCustomer.cust_id,
        ids: quoteIds || this.filters.id.value,
        contact: [this.getUser.contact_id],
        dateStart: dateFilter.selectedDateStart,
        dateEnd: dateFilter.selectedDateEnd || dateFilter.selectedDateStart,
        status: this.filters.status.value,
        correls: "quote_amount",
        sortBy: sortBy,
        sortOrder: this.sort.sortOrder === -1 ? "DEC" : "",
      }).then((response: any) => {
        if(addSoQuote) {
          this.soQuotes.push(...response.soquote_items)
        } else {
          this.$emit('lastUpdated', Date.now());
          this.soQuotes = response.soquote_items;
        }
        }).finally(() => {
          this.isLoadingSoQuotes = false;
        });
      this.first = 0;
      this.page = 1; //might be totally unecessary
    },
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = Utils.formatDate(dueDate);

      return reformatedDueDate;
    },
    formatCurrency(amount: string){
      return Utils.formatPrice(amount);
    },
    updateFilters(event: any) {
      this.filters = event
      this.fetchQuotesData(false)
    },
    sortQuotes(event: any) {
      this.sort.sortOrder = event.sortOrder;
      this.sort.sortBy = event.sortField;
      this.fetchQuotesData(false);
    },
    pageClick(event: any) {
      this.setFirstRow(event.first);
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1 ||
          event.page == event.pageCount - 2) &&
        this.soQuotes.length == this.getRangeEnd - 100
      ) {
        this.fetchQuotesData(true) 
      }
    },
    rowClick(event: any) {
      let itemToEdit = event;
      if (event.data) {
        itemToEdit = event.data;
      }
      this.setQuoteToEdit(itemToEdit)
      this.$router.push('/sales/edit-quote');
    },
    downloadIcon(id: any) {
      let downloading = this.getLoadingQuotes?.includes(id) || false;
      let downloaded = this.getQuotes;
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.id === id) === undefined || this.forcePDFFetch &&
          !downloading ,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf": 
          downloaded.find((i: any) => i.id === id) !== undefined &&
          !downloading ,
      }
    },
    async download(id: any) {
      if(this.showPricesCheckboxOnDialog || this.showModelsCheckboxOnDialog) {
        this.forcePDFFetch = true;
        this.showPDFOptionsDialog = true;
       // wait for dialog to be closed before proceeding
       await this.waitForDialogToClose();
      }
      let modelNo = 'N'
      let prices = 'N'
        if(this.showModelsSelection) {
          modelNo = 'Y'
        }
        if(this.showPricesSelection) {
          prices = 'Y'
        }
      this.fetchQuotePDF({
        recordId: id, modelNo: modelNo, prices: prices, forceFetch: this.forcePDFFetch
      });
},
  onPDFOptionDialogClosed() {
    this.PDFOptionDialogClosedResolve();
    this.showPDFOptionsDialog = false;
  },
    waitForDialogToClose() {
    return new Promise((resolve) => {
      this.PDFOptionDialogClosedResolve = resolve;
    });
  },
    handleEmailOrderPDF(quoteId: string) {
      this.showEmailPDFDialog = true;
      this.quoteIdToSend = quoteId;
    },
    closeSendEmailDialog() {
      this.showEmailPDFDialog = false;
      this.quoteIdToSend = '';
    },
    async handleSendEmail(data: any) {  
      this.emailLoading = true    
      const response = await soQuotesService.SOQuotePdf(this.quoteIdToSend, data.emailToSend, data.modelNo, data.prices);
      this.emailLoading = false
      this.closeSendEmailDialog();

      if (response === 'success') {
        store.dispatch("notification/add", {
          message: `Quote has been emailed successfully`,
          type: "success",
        });
      }
    },
    handleShowReorderConfirmation(items: any[]) {
      if (!items || !items.length) {
        return;
      }
      if(!this.getOrder || this.getOrder.length === 0) {
        this.handleReorderItems(items)
      } else {
        this.showReorderConfirmation = true;
        this.addingReorderItems = items;
      }

    },
    handleReorderItems(items: any[]) {
      const getCartQuotePriceModel = this.getCartQuotePriceModel;
      items
      .filter(item => item.part && item.part.trim() !== "")
      .forEach((item: any) => {
        const data = {
          code_items: [],
          desc: item.desc_items?.length ? item.desc_items[0].desc : `Part No. ${item.part}`,
          part_number: item.part,
          price: getCartQuotePriceModel === "cost" ? item.cost : item.qty_items[0]?.price ,
          quantity: item.qty_items[0]?.qty ? parseFloat(item.qty_items[0]?.qty).toFixed(2) : 0,
          std_price_items: [],
          web_category: []
        }
        this.addItem(data)
      })
      
      this.showReorderConfirmation = false;
      this.addingReorderItems = []
      setTimeout(() => {
        this.$router.push('/sales');
      }, 1000);
    },
    handleClearAndReorderItems(items: any[]) {
      this.clearOrder()
      this.handleReorderItems(items)
    },
    addItem(part: any) {
      const index = this.getOrder.findIndex((item: any) => {
        return (item.part_number === part.part_number) && (item.price === part.price)
      });
      if(index >= 0) {
        this.getOrder[index].quantity = (parseFloat(this.getOrder[index].quantity) + parseFloat(part.quantity)).toFixed(2).toString(); 
        this.updatePartQty({partIndex: index, part: this.getOrder[index]})
      } else {
        if(part.quantity > 0) {
          part['amount'] = part.price;
          this.addPartToOrder(JSON.parse(JSON.stringify(part)))
        }
      }
    },
  },
})
