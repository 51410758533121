
import { defineComponent } from "vue";
import store from "@/store";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Calendar from 'primevue/calendar';
import Utils from "@/utility/utils";

type Action = {
  name: string;
  icon?: string;
  getIconClass?: (data: any) => { [key: string]: boolean };
  tooltipMsg: string;
  action: (data: any) => void;
};

export default defineComponent({
  name: "DataGrid",
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Dropdown,
    Calendar,
  },
  created() {
    if(this.filters)
      this.localFilters = this.filters 
  },
  updated() {
    if(this.filters)
      this.localFilters = this.filters
  },
  mounted() {
    this.firstRow = store.getters[`${this.firstRowGetter}`];
  },
  props: {
    items: Array,
    sortOrder: Number,
    sortField: String,
    columns: Array as () => any[],
    breakpoint: String,
    dataKey: String,
    selectionMode: String,
    expansion: Boolean,
    expansionName: String,
    firstRowGetter: String,
    filters: Object,
    actions: {
    type: Array as () => Action[],
      default: [] as Action[],
    },
    rowsPerPage : {
      type: Number,
      default: 5
    },
  },
  emits: ["onPageClicked", "delete", "select-row", "fetchData", "onSort", "onRowsPerPageChange"],
  methods: {
    getColumnBodyStyle(col: any) {
      return col.format === "currency" ? "text-align: right; padding-right: 4rem" : "";
    },
    deleteRow(event: any) {
      this.$emit("delete", event.target.id);
    },
    rowClick(event: any) {
      this.$emit("select-row", event);
    },
    onPageClicked(event: any) {
      this.$emit("onPageClicked", event);
    },
    onSort(event: any) {
      this.$emit("onSort", event);
    },
    clearFilter(field: any) {
      let filterObject = this.filters
      this.localFilters[field as keyof typeof filterObject].value = undefined as any
      this.localFilters[field as keyof typeof filterObject].newVal = undefined as any
      this.$emit("fetchData", this.localFilters);
    },
    onFetchData(event: string) {
      this.columns?.forEach((col: any) => {
        let filterObject = this.filters
        if(col.filter) {
          this.localFilters[col.field as keyof typeof filterObject].value = this.localFilters[col.field as keyof typeof filterObject].newVal 
        }
      });
        
      this.$emit("fetchData", this.localFilters);
    },
    formatCurrency(amount: string){
      return Utils.formatPrice(amount);
    },
  },
  data() {
    return {
      selectedRow: null,
      firstRow: 0,
      expandedRows: [],
      localFilters: {} as any,
    };
  },
});
